@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    p {
      @apply mb-6 text-lg lg:text-xl;
    }
    h1{
      @apply text-black text-3xl md:text-6xl font-semibold mb-5 mt-10;
    }
    h2{
      @apply text-4xl font-semibold;
    }
  }


@import "_fonts.scss";


html {
    scroll-behavior: smooth;
}


.custom-rotate-1 {
    rotate: (-15deg);
}

.custom-rotate-2 {
    rotate: (5deg);
}

.custom-rotate-3 {
    rotate: (15deg);
}

.custom-rotate-4 {
    rotate: (-15deg);
}

.custom-rotate-5 {
    rotate: (15deg);
}

.custom-rotate-6 {
    rotate: (5deg);
}

.custom-rotate-7 {
    rotate: (-15deg);
}

@media (min-width: 1536px) {
    .container {
      max-width: 1312px!important;
    }
  }
